// Constants
import { NUMBER } from 'constants/number.constants';
import { FILES_INITIAL_STATE } from 'containers/transactions/views/report/components/fraudulent-movement/contexts/constants/transactions-report-fraudulent-movement.context.constants';
// Types
import { TransactionsReportFraudulentMovementUploadDocumentsActionsHandlersReturnType } from './types/transactions-report-fraudulent-movement-upload-documents-actions-handlers-return.type';
import { TransactionsReportFraudulentMovementUploadDocumentsActionsHandlersPropsType } from './types/transactions-report-fraudulent-movement-upload-documents-actions-handlers-props.type';

const goBackHandler = ({ setCurrentStep, setFiles }): void => {
  setCurrentStep(NUMBER.ONE);
  setFiles(FILES_INITIAL_STATE);
};

const TransactionsReportFraudulentMovementUploadDocumentHandlers = ({
  setFiles,
  setCurrentStep,
}: TransactionsReportFraudulentMovementUploadDocumentsActionsHandlersPropsType): TransactionsReportFraudulentMovementUploadDocumentsActionsHandlersReturnType => {
  return {
    handleGoBack: () => goBackHandler({ setCurrentStep, setFiles }),
  };
};

export { TransactionsReportFraudulentMovementUploadDocumentHandlers };
