// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { WIZARD_CANCEL_MODAL_PROPS } from 'containers/transactions/views/report/constants/transactions-report.constants';
import { FIRST_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Hooks
import { TransactionsReportFraudulentMovementConsumerHook } from 'containers/transactions/views/report/components/fraudulent-movement/contexts/transactions-report-fraudulent-movement.context';
// Utils
import { hasSelectedOptions } from './utils/transactions-report-fraudulent-movement-additional-info-actions.utils';

const TransactionsReportFraudulentMovementAdditionalInfoActionsComponent =
  (): React.ReactElement => {
    const {
      selectedOptions: {
        notRecognizeChargeHaveYourCard,
        notRecognizeChargeClickLink,
        notRecognizeChargeMotiveFraud,
      },
    } = TransactionsReportFraudulentMovementConsumerHook();

    return (
      <WizardStepFooterComponent
        {...WIZARD_CANCEL_MODAL_PROPS}
        rightButtonConfiguration={{
          disabled: !hasSelectedOptions({
            notRecognizeChargeHaveYourCard,
            notRecognizeChargeClickLink,
            notRecognizeChargeMotiveFraud,
          }),
        }}
        targets={FIRST_STEP_TARGETS}
      />
    );
  };

export { TransactionsReportFraudulentMovementAdditionalInfoActionsComponent };
