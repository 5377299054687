// Constants
import { NUMBER } from 'constants/number.constants';
// Enumerations
import { AddressTypesEnum } from 'components/block-card/components/enumerations/address-types.enumeration';
// Types
import { GoBackHandlerPropsType } from './types/go-back-handler-props.type';
import { TransactionsReportFraudulentMovementBlockCardActionsHandlersPropsType } from './types/transactions-report-fraudulent-movement-block-card-actions-handlers-props.type';
import { TransactionsReportFraudulentMovementBlockCardActionsHandlersReturnType } from './types/transactions-report-fraudulent-movement-block-card-actions-handlers-return.type';

const goBackHandler = ({
  setBlockReason,
  setCurrentStep,
  setSelectedAddress,
}: GoBackHandlerPropsType): void => {
  setBlockReason(null);
  setCurrentStep(NUMBER.TWO);
  setSelectedAddress(AddressTypesEnum.HBTL);
};

const TransactionsReportFraudulentMovementBlockCardActionsHandlers = ({
  setBlockReason,
  setCurrentStep,
  setSelectedAddress,
}: TransactionsReportFraudulentMovementBlockCardActionsHandlersPropsType): TransactionsReportFraudulentMovementBlockCardActionsHandlersReturnType => {
  return {
    handleGoBack: () => goBackHandler({ setBlockReason, setCurrentStep, setSelectedAddress }),
  };
};

export { TransactionsReportFraudulentMovementBlockCardActionsHandlers };
