// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { WIZARD_CANCEL_MODAL_PROPS } from 'containers/transactions/views/report/constants/transactions-report.constants';
// Hooks
import { useTransactionsReportPaymentAdditionalInfoActionsHook } from './hooks/transactions-report-payment-additional-info-actions.hook';
// Types
import { TransactionsReportPaymentAdditionalInfoActionsComponentType } from './types/transactions-report-payment-additional-info-actions.component.type';

export const TransactionsReportPaymentAdditionalInfoActionsComponent = ({
  handleSubmit,
  isValid,
}: TransactionsReportPaymentAdditionalInfoActionsComponentType): React.ReactElement => {
  const { handleNextButtonClick } = useTransactionsReportPaymentAdditionalInfoActionsHook();

  return (
    <WizardStepFooterComponent
      {...WIZARD_CANCEL_MODAL_PROPS}
      rightButtonConfiguration={{
        disabled: !isValid,
        onClick: handleSubmit(handleNextButtonClick),
      }}
    />
  );
};
