// Constants
import { NUMBER } from 'constants/number.constants';
// Enumerations
import { AddressTypesEnum } from 'components/block-card/components/enumerations/address-types.enumeration';
// Hooks
import { BlockCardContextConsumerHook } from 'components/block-card/contexts/block-card.context';
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
// Types
import { UseTransactionsReportFraudulentMovementBlockCardActionsHookType } from './types/transactions-report-fraudulent-movement-block-card-actions-hook.type';
import { TransactionsReportFraudulentMovementBlockCardActionsHandlers } from '../handlers/transactions-report-fraudulent-movement-block-card-actions.handlers';

const useFraudulentMovementBlockCardActionsHook =
  (): UseTransactionsReportFraudulentMovementBlockCardActionsHookType => {
    const { blockReason, setBlockReason, setSelectedAddress } = BlockCardContextConsumerHook();
    const { setCurrentStep } = useWizardContextConsumerHook();
    const { handleGoBack } = TransactionsReportFraudulentMovementBlockCardActionsHandlers({
      setBlockReason,
      setCurrentStep,
      setSelectedAddress,
    });

    return {
      blockReason,
      handleGoBack,
    };
  };

export { useFraudulentMovementBlockCardActionsHook };
