// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { DEFAULT_DISABLED_VALUE } from './constants/transactions-report-fraudulent-movement-upload-documents-actions.constants';
import { WIZARD_CANCEL_MODAL_PROPS } from 'containers/transactions/views/report/constants/transactions-report.constants';
import { SECOND_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Hooks
import { useFradulentMovementUploadDocumentsActionsHook } from './hooks/transactions-report-fraudulent-movement-upload-documents-actions.hook';

export const TransactionsReportFraudulentMovementUploadDocumentsActionsComponent =
  (): React.ReactElement => {
    const { handleGoBack } = useFradulentMovementUploadDocumentsActionsHook();

    return (
      <WizardStepFooterComponent
        {...WIZARD_CANCEL_MODAL_PROPS}
        hasDefaultLeftButton
        leftButtonConfiguration={{
          onClick: handleGoBack,
        }}
        rightButtonConfiguration={DEFAULT_DISABLED_VALUE}
        targets={SECOND_STEP_TARGETS}
      />
    );
  };
