// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { WIZARD_CANCEL_MODAL_PROPS } from 'containers/transactions/views/report/constants/transactions-report.constants';
import { WIZARD_CONFIRM_BUTTON } from 'translations/wizard.translations';
// Hooks
import { useTransactionsReportDuplicateChargeConfirmationActionsHook } from './hooks/transactions-report-duplicate-charge-confirmation-actions.hook';

export const TransactionsReportDuplicateChargeConfirmationActionsComponent =
  (): React.ReactElement => {
    const { challenge, challengeError, handleConfirmButtonClick } =
      useTransactionsReportDuplicateChargeConfirmationActionsHook();

    return (
      <WizardStepFooterComponent
        {...WIZARD_CANCEL_MODAL_PROPS}
        rightButtonConfiguration={{
          assetConfiguration: {},
          children: <FormattedMessageComponent id={WIZARD_CONFIRM_BUTTON} />,
          disabled: !challenge || challengeError,
          onClick: handleConfirmButtonClick,
        }}
      />
    );
  };
