// Vendors
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
// Constants
import { THEME_PROVIDER_PROPS } from './constants/entrypoint.constants';
// Entrypoint common
import EntrypointCommon from 'entrypoint/entrypoint.common';
// Providers
import MobileConnectorContextProvider from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
import ThemeProvider from 'providers/theme/theme.provider';
import { PublicInteractionsProvider } from 'providers/public-interactions/public-interactions.provider';
import { FeatureFlagsProvider } from 'providers/feature-flags/feature-flags.provider';

const Entrypoint = (): React.ReactElement => (
  <ThemeProvider {...THEME_PROVIDER_PROPS}>
    <BrowserRouter>
      <MobileConnectorContextProvider>
        <PublicInteractionsProvider>
          <FeatureFlagsProvider>
            <EntrypointCommon />
          </FeatureFlagsProvider>
        </PublicInteractionsProvider>
      </MobileConnectorContextProvider>
    </BrowserRouter>
  </ThemeProvider>
);

export default Entrypoint;
